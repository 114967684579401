import React, { useState,useEffect} from "react";
import { connect,useDispatch,useSelector } from "react-redux";

let refWebSocket = null


const BingoClient = (props) => {  
  let [numberAct, setNumberAct] = useState("-");
  let [labelText, setLabelText] = useState("-");

  const dispatch = useDispatch();

  
  const RenderRow = (props) =>{
    return props.data.map((key, index)=>{        
    return <td className={key["EventoJugadaNumeroId"]!==null || key["NumeroJugada"]===100?"selectedNumber":""} key={key["NumeroJugada"]}>{key["NumeroJugada"]===100?"FREE":key["NumeroJugada"]}</td>
    })
   }
   function getRowsData(numberList){    
        const chunkSize = 5;
        /*const groups = numberList.map((e, i) => { 
            return i % chunkSize === 0 ? numberList.slice(i, i + chunkSize) : null; 
        }).filter(e => { return e; });   */
        
        let groupsRr = [[],[],[],[],[]]
        let colIndex = 0
        numberList.map((e, i) => {                        
            groupsRr[colIndex].push(e)
            colIndex++
            if((colIndex) % chunkSize === 0){
                colIndex = 0
            }
        })

        return groupsRr.map((row, index)=>{
        return <tr key={index}><RenderRow key={index} data={row} /></tr>
        })
    }    
    function aceptarBingoLocal(){
        props.aceptarBingo(props.numberList[0]["UsuarioId"],props.index)
    }
    function rechazarBingoLocal(){
        props.rechazarBingo(props.numberList[0]["UsuarioId"],props.index)
    }    
  return (
    <div>       
       <div className='bingo'>
           <div>
            <div className='titulo-bingo'>BINGO - {props.numberList!=null && props.numberList.length>0?props.numberList[0]["Nombre"].toUpperCase():"S/N"}           
            </div>            
            <br/>
           </div>
            <table className='tableColor'>
            {
                getRowsData(props.numberList)
            }
            </table>
            <button className='btnBingo' onClick={aceptarBingoLocal}>ACEPTAR</button>           
            <button className='btnBingo' onClick={rechazarBingoLocal}>RECHAZAR</button>          
       </div>
    </div>
  );
};
const mapStateToProps = state => ({  
});
export default connect(mapStateToProps, {    
})(BingoClient);