import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Router, Switch, Route} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";




import BingoServer from "./components/BingoServer";
import BingoClient from "./components/BingoClient";
import Home from "./components/Home";
import Condiciones from "./components/Condiciones";






import { history } from "./helpers/history";

const App = () => {


  return (
    <Router history={history}>
      <div className="mainContainer">
        
        <div>
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            
            <Route path="/BingoControlPSA" component={BingoServer} />
            <Route path="/Bingo" component={BingoClient} />
            <Route path="/BingoClient" component={BingoClient} />
            <Route path="/Condiciones" component={Condiciones} />
            <Route path='*' exact={true} component={Home} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
