import React, { useState,useEffect} from "react";
import { connect,useDispatch,useSelector } from "react-redux";
import logo1 from '../image/logo01.png';

let refWebSocket = null


const Condiciones = (props) => {    
  return (
    <div>       
       <div className='bingo'>
           <div>
            <div className='titulo-bingo'>INSTRUCCIONES          
            </div>            
            <br/>
            <br/>
            <br/>
           </div>
            <div className='condicionestext'>                
                <p>
•	Existirá un único ganador, en el caso de completar la cartilla dos personas, el primero que oprima el botón BINGO en la parte inferior central de la cartilla será el ganador.
</p>
<p>
•	La línea podrá ser jugada de manera vertical u horizontal, para validar su ganancia debe ser pulsado el botón BINGO en la parte inferior de la cartilla.
</p>
<p>
•	El bingo virtual utiliza 75 números, dispuestos aleatoriamente en cada cartilla, distribuido de 5 filas y 5 columnas.
</p>
<p>
•	Cada link de acceso a la cartilla es único por lo que no podrá jugar más de una persona con mencionado link.
</p>
<p>
•	La cartilla puede ser utilizada para todos los premios (15 juegos dispuestos en 5 cartillas completas y 10 líneas).
</p>
<p>
•	Cada jugada se presentará con una cartilla diferente, que se actualizará automáticamente.
</p>
<p>
•	Cada uno de los ganadores será contactado luego de la finalización de los juegos para la entrega del premio correspondiente.
</p>
<p>
•	La locución de los números del bingo será a través del link ZOOM y el acceso a la cartilla será a través del link del aplicativo.
</p>
<p>
•	Se sugiere si posee dos o más cartillas hacer uso de una sola reunion ZOOM de locución.
</p>
<p>
•	El bingo podrá ser jugado desde cualquier dispositivo sea: celular, Tablet, PC, etc.
</p>
<p>
•	De presentarse cualquier inconveniente por favor indicarlo a través del chat de ZOOM o directamente a la persona que vendió la cartilla.
</p>
            </div>

       </div>
       <br/><br/>
       <div className="logo1"><img src={logo1} alt="Logo Bingo" /></div>                                       
    </div>
  );
};
const mapStateToProps = state => ({  
});
export default connect(mapStateToProps, {    
})(Condiciones);