import React, { useState, useEffect } from "react";
import logo1 from '../image/logo01.png';


const Home = () => {

  return (
    <div className="container">
      <header className="jumbotron">
        <div className="logoHome"><img src={logo1} alt="Logo Bingo" /></div>
        <br/>
        <div className='text-noactivo'>Estará activo el día sábado 12 de febrero de 2022 - 15h00</div>
      </header>      
    </div>
  );
};

export default Home;
