import React from 'react';
import styled, { keyframes } from 'styled-components';
import RouletteItem from './roulette-item';
import { IRouletteItem } from '../../models';
import { Pickable, RouletteAnimatable, WithHeight } from '../../constants/types';

type Wrapper = Pickable & WithHeight

export const RouletteWrapper = styled.div<Wrapper>`
    width: 120px;
    height: ${props => props.height}px;
    margin: 0px 0px;
    position: relative;
    overflow: hidden; 
    background: transparent !important;
`;
export const Gradient = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px; 
    background: linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%)
`;
const scale = () => keyframes`
  to {
    top: -30px
  }
`;
export const RouletteContentContainer = styled.div<RouletteAnimatable>`
  position: absolute;
  width: 100%;
  top: ${props => props.from}px;
	animation: ${scale} ${props => props.duration}ms;
	animation-fill-mode: forwards;
`;

interface IProps {
	itemHeight: number;
	rouletteHeight: number;
	data: IRouletteItem[];
	prize: IRouletteItem;
	animationDuration: number;
	canPick: boolean;
	onRollEnd?: () => void;
	onPick?: () => void;
	picked: boolean;
}

interface IState {
	from: number;
	data: IRouletteItem[];
}

class Roulette extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		const data = this.shuffle([...props.data]);
		data.splice(1, 0, props.prize);
		this.state = {
			from: -((props.data.length - 2) * props.itemHeight),
			data
		};
	}
	forceUpdateRE = (dataRe:IRouletteItem[]) =>{
		
		const data = this.shuffle(dataRe);
		data.splice(1, 0, this.props.prize);
		this.setState({
			from: -((dataRe.length - 2) * this.props.itemHeight),
			data
		})		

		this.forceUpdate();

		const elem = document.querySelector( 'div > .sc-htoDjs' );
		elem?.classList.remove( 'cVfdKD' );
		setTimeout(function(){
			elem?.classList.add( 'cVfdKD' );
		},100)
		
	};
	shuffle(array: IRouletteItem[]) {
		return array.sort(() => Math.random() - 0.5);
	}

	onRollEnd = () => {
		this.props.onRollEnd && this.props.onRollEnd();
	};

	renderItem = (height: number) => (item: IRouletteItem, index: number) => <RouletteItem key={`${item.id} ${index}`} item={item} height={height} picked={this.props.picked} />;

	
	render() {
		const { rouletteHeight, itemHeight, picked } = this.props;
		
		return (
			<RouletteWrapper height={rouletteHeight} picked={picked}>
				<RouletteContentContainer
					from={this.state.from}
					duration={this.props.animationDuration}
					onAnimationEnd={() => this.onRollEnd()}>
					{this.state.data.map(this.renderItem(itemHeight))}
				</RouletteContentContainer>
				<Gradient onClick={() => this.props.canPick ? this.props.onPick && this.props.onPick() : {}} />
			</RouletteWrapper>
		);
	}
}


export default Roulette;
